@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

:root {
  --primary: #131619;
  --secondary: #e4e4e4;
  --bg-color: #f2f4f8;
  /* --bg-color: rgb(242, 244, 248); */
  --gradient: linear-gradient(
    135deg,
    #4e66b5 0,
    #e8b892 50%,
    #e8b892 70%,
    #fe9a5c 100%
  );

  --golden: #e8b892;
  --animate-delay: 0.7s;
  /* --raleway: 'Raleway', sans-serif; */
}

html {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'DM Sans', sans-serif;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

.rotate-center {
  -webkit-animation: rotate-center 2s ease-in-out both infinite;
  animation: rotate-center 2s ease-in-out both infinite;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  35% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  35% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.autoplay-progress {
  position: absolute;
  left: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  /* color: var(--swiper-theme-color); */
  color: #fff;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  /* stroke: var(--swiper-theme-color); */
  stroke: #fff;
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

/* .swiper-pagination .swiper-pagination-bullet {
  transition: all 0.3s ease-in-out;
  background: #fff;
}
.swiper-pagination .swiper-pagination-bullet-active {
  transition: all 0.3s ease-in-out;
  background: #fff;
  height: 30px;
  border-radius: 10px;
} */

.text-up {
  transform: translateY(-100%);
}

.text-gradient {
  background: linear-gradient(
    135deg,
    #4e66b5 0,
    #e8b892 20%,
    #e8b892 70%,
    #fe9a5c 100%
  );
  background-size: 150%;
  font-weight: 800 !important;
  line-height: 0.8;
  background-repeat: no-repeat;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.height-100 {
  height: 100%;
  height: 100vh;
  height: 100dvh;
  height: 100svh;
}

.height-50 {
  height: 50%;
  height: 50vh;
  height: 50dvh;
  height: 50svh;
}
